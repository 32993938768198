import React, { useEffect, useState } from "react";
import AppLayout from "../../Widgets/AppLayout";
import { useNavigate } from "react-router";
import { BsPlus, BsSearch } from "react-icons/bs";
import { CiLocationOn } from "react-icons/ci";

import AlertDialog from "../../Widgets/SuccessDialog";
import PlaceHolderOne from "../../Widgets/placeholders/PlaceHolderOne";
import branchesPlaceholderImg from "../../../Assets/placholders/branchesPlaceholder.png";
import MapIcon from "../../../Assets//map_icon.svg";
import ScanCodeIcon from "../../../Assets//scan-barcode.svg";
import FlagIcon from "../../../Assets//flag.svg";
import DeleteIcon from "../../../Assets//trash.svg";
import Tour from "reactour";

import { FillButton } from "../../Widgets/FormUtils/FormUtils";
import { Loader } from "../../Widgets/Loader";

import Tooltip from "@mui/material/Tooltip";
import CreateBranchModal from "./Tabs/Branches/Modal/CreateBranchModal";
import ViewQRCode from "./BranchTab/Modal/QRModal";
import GoogleMapView from "./BranchTab/Modal/GoogleMapModal";
import { useDispatch, useSelector } from "react-redux";
import { getBranchesAsync } from "../../../Store/Branches";
import { branchCustomizeHelper, branchTourGuide } from "./branchLocationTour";
import { BranchTourModal } from "./BranchTour";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

export function BranchLocation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let user = localStorage.getItem("user");
  user = user && JSON.parse(user);

  const handleDirectSendBulkAccountCreation = () => {
    navigate("/branches/bulk-account");
  };

  const [openModal, setModalView] = useState(false);
  const [openMapModal, setMapModalView] = useState(false);
  const [isTourOpen, setOpenTour] = React.useState(false);
  const [isModalOpen, setModalOpen] = React.useState(false);

  const [searchBranchName, setSearchBranchName] = useState("");
  const [createBranch, setCreateBranch] = useState(false);
  const [selectedRow, selectBranch] = useState({});
  const [selectGeodata, setGeoData] = useState({});
  const branches = useSelector((state) => state.branches)?.branches?.data || [];
  const isloading = useSelector((state) => state.branches)?.loading;

  const handleOpen = () => {
    setCreateBranch(true);
  };
  const handleClose = () => {
    setCreateBranch(false);
  };

  const callBack = () => {
    dispatch(getBranchesAsync()).then((res) => {});
  };

  useEffect(() => {
    callBack();
    if (user?.new_user_location === 0) {
      setModalOpen(true);
    }
  }, []);

  // handling clicking on a branch
  const handleClickBranch = (value) => {
    navigate(`/branches/${value.id}`, {
      state: {
        branchName: value.name,
      },
    });
  };

  const handleBranchSearch = (branchName) => {
    const filtereddata = branches?.filter((branch) =>
      branch?.name?.toLowerCase().includes(branchName?.toLowerCase()),
    );
    return filtereddata;
  };

  const handleSearchAction = (e) => {
    setSearchBranchName(e.target.value);
  };

  const handleOpenModal = () => {
    setModalView(true);
  };

  const handleCloseModal = () => {
    setModalView(false);
  };

  const handleOpenMapModal = () => {
    setMapModalView(true);
  };

  const handleCloseMapModal = () => {
    setMapModalView(false);
  };

  const handleCloseTour = () => {
    setOpenTour(false);
    setModalOpen(false);
  };

  const handleOpenTourModal = () => {
    setModalOpen(false);
    setOpenTour(true);
  };

  const rowCell =
    "dark:text-white border border-6E6 dark:border-333 whitespace-nowrap";

  return (
    <section className="homepage">
      <AppLayout
        custom="company"
        subCustom="branch"
        title="Manage Location information"
        customTitle=" "
        subtitle="Manage company's location and create new location."
        RightComponent={
          <div className="flex items-center gap-4">
            <button
              data-tour="step1"
              onClick={handleOpen}
              className="p-3 rounded bg-5EB text-black flex items-center "
            >
              {" "}
              <BsPlus size={23} />{" "}
              <span className="w-max">Create new Location</span>
            </button>
          </div>
        }
      >
        <section className="branches_ mb-40">
          <AlertDialog />

          <Tour
            isOpen={isTourOpen}
            steps={branchTourGuide}
            onRequestClose={handleCloseTour}
            disableDotsNavigation
            closeWithMask={false}
            showNumber={false}
            startAt={0}
            showButtons={false}
            CustomHelper={({ ...props }) => (
              <div>
                {branchCustomizeHelper[props.current]({
                  ...props,
                  navigate,
                  handleCloseTour,
                })}{" "}
              </div>
            )}
          />

          <div className="header w-full" style={{ marginTop: "0px" }}>
            <div
              data-tour="step3"
              className="search-box !justify-start items-center dark:bg-828 !w-full !rounded-3xl !ml-0"
            >
              <div>
                <BsSearch className="dark:text-white" />
              </div>
              <input
                className="dark:text-white"
                onChange={handleSearchAction}
                type="text"
                placeholder="I'm searching for..."
              />
            </div>
            {/* <BranchButton handleOpen={handleOpen}  /> */}
          </div>

          {!isloading ? (
            <>
              {handleBranchSearch(searchBranchName)?.length > 0 && (
                <TableContainer
                  component={Paper}
                  data-tour="step2"
                  sx={{ overflowX: "auto" }}
                >
                  <Table
                    sx={{
                      minWidth: 650,
                      "& td, & th": {
                        borderRight: "none",
                        borderLeft: "none",
                      },
                    }}
                    aria-label="branch table"
                  >
                    <TableHead>
                      <TableRow className="h-[68px] bg-BF2 text-color-424 font-medium text-base dark:bg-1F1">
                        <TableCell
                          align="center"
                          className={`rounded-tl-[8px] w-[200px] ${rowCell}`}
                        >
                          Location name
                        </TableCell>
                        <TableCell align="center" className={rowCell}>
                          Location address
                        </TableCell>
                        <TableCell align="center" className={rowCell}>
                          Number of team{" "}
                        </TableCell>
                        <TableCell align="center" className={rowCell}>
                          Members
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`rounded-tr-[8px] ${rowCell}`}
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {handleBranchSearch(searchBranchName)?.map(
                        (val, index) => (
                          <TableRow key={index} className="text-sm">
                            <TableCell
                              align="left"
                              className={`py-[14px] ${rowCell}`}
                            >
                              {val.name}
                            </TableCell>
                            <TableCell align="left" className={rowCell}>
                              {val.address}
                            </TableCell>
                            <TableCell align="center" className={rowCell}>
                              {val.memberCount}
                            </TableCell>
                            <TableCell align="center" className={rowCell}>
                              <p
                                onClick={() => handleClickBranch(val)}
                                className="cursor-pointer font-medium text-D57 underline"
                              >
                                See all members
                              </p>
                            </TableCell>
                            <TableCell align="center" className={rowCell}>
                              <div className="flex items-center justify-center gap-3">
                                {val["longitude"] !== null &&
                                  val["latitude"] !== null && (
                                    <Tooltip
                                      title="View on map"
                                      placement="top-start"
                                    >
                                      <div>
                                        <img
                                          src={MapIcon}
                                          alt="logo"
                                          className="cursor-pointer dark:text-white"
                                          onClick={() => {
                                            setGeoData(val);
                                            handleOpenMapModal();
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                  )}

                                <Tooltip
                                  title="Scan Qrcode"
                                  placement="top-start"
                                >
                                  <div>
                                    <img
                                      src={ScanCodeIcon}
                                      alt="scan_code"
                                      className="cursor-pointer dark:text-white"
                                      onClick={() => {
                                        handleOpenModal();
                                        selectBranch(val);
                                      }}
                                    />
                                  </div>
                                </Tooltip>

                                {/* <Tooltip title="View members" placement='top-start'>
                            <div>
                              <img src={ViewIcon} alt="member" className="cursor-pointer dark:text-white"  />
                            </div>
                          </Tooltip> */}

                                <Tooltip
                                  title="Benchmark"
                                  placement="top-start"
                                >
                                  <div>
                                    <img
                                      src={FlagIcon}
                                      alt="flag"
                                      className="cursor-pointer dark:text-white"
                                      onClick={() =>
                                        navigate(
                                          `/branches/bench-marks/${val.id}`,
                                        )
                                      }
                                    />
                                  </div>
                                </Tooltip>

                                <Tooltip title="Delete" placement="top-start">
                                  <div>
                                    <img
                                      src={DeleteIcon}
                                      alt="delete"
                                      className="cursor-pointer text-red-700 dark:text-white"
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                        ),
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {handleBranchSearch(searchBranchName)?.length < 1 && (
                <div className="flex flex-col items-center justify-center gap-10">
                  <PlaceHolderOne
                    pic={branchesPlaceholderImg}
                    text="No locations created yet"
                  />
                  <FillButton
                    text="Create location"
                    width={"300px"}
                    height={"50px"}
                    callBack={handleOpen}
                  />
                </div>
              )}
            </>
          ) : (
            <Loader />
          )}

          {openModal && (
            <ViewQRCode
              data={selectedRow}
              open={openModal}
              handleClose={handleCloseModal}
            />
          )}

          {openMapModal && (
            <GoogleMapView
              data={selectGeodata}
              open={openMapModal}
              handleClose={handleCloseMapModal}
            />
          )}

          {createBranch && (
            <CreateBranchModal
              open={createBranch}
              handleClose={handleClose}
              callback={callBack}
            />
          )}

          {isModalOpen && (
            <BranchTourModal
              open={isModalOpen}
              handleClose={handleCloseTour}
              handleTour={handleOpenTourModal}
            />
          )}
        </section>
      </AppLayout>
    </section>
  );
}
