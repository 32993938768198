/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import './TeamMember.css';
import { BsPlus, BsSearch, BsTrash2 } from 'react-icons/bs';
import { useState, useEffect } from 'react';
import TeamMemberModal from './Modal/TeamMemberModal';
import { Branch } from '../../../../../Network/ServiceClass/Branch';
import Menu from '@mui/material/Menu';
import pen from '../../../../../Assets/Pen.png';
import WriteUpIcon from '../../../../../Assets/write_icon.svg';
import AssignTaskIcon from '../../../../../Assets/assign_task.svg';
import DisableIcon from '../../../../../Assets/trash.svg';
import ReassignIcon from '../../../../../Assets/reassign_icon.svg';
import layers from '../../../../../Assets/Layers.png';
import MenuItem from '@mui/material/MenuItem';
import { WriteUpModal } from './Modal/WriteUpModal';
import { InviteButton } from './Widget/InviteButton';
import CreateTask from './Widget/CreateTask';
import { CircularProgress, Stack } from '@mui/material';
import { Storage } from '../../../../../Network/StorageClass/StorageClass';
import AlertDialog from '../../../../Widgets/SuccessDialog';
import { AnimatePresence, motion } from 'framer-motion';
import PlaceHolderOne from '../../../../Widgets/placeholders/PlaceHolderOne';
import { useDispatch, useSelector } from 'react-redux';
import { getTeammemberAsync, updateUserStatusAsync } from '../../../../../Store/Branches';
import { toast } from 'react-toastify';
import CustomDropdown from '../../../../Widgets/CustomDropdown';
import moment from 'moment';
import InitialsGen from '../../../../Widgets/InitialsGen';
import PopDetail from '../../../../Widgets/Details/PopDetail';
import UserDetail from '../../../../Widgets/Details/UserDetail';
import { getBranchesAsync, reassignBranchAsync } from '../../../../../Store/Branches';
import { CustomPopper } from '../../../../Widgets/CustomPopper';
import { ClickableItem } from '../../../../Widgets/ClickableItem';
import { IoIosArrowBack } from 'react-icons/io';
import { useLocation, useNavigate, useParams } from 'react-router';
import AppLayout from '../../../../Widgets/AppLayout';
import { CiLocationOn } from 'react-icons/ci';

const branch = new Branch();

export const BranchTeamMembers = () => {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const storage = new Storage();
  const dispatch = useDispatch();
  const teamMembersStore = useSelector(state => state.branches)?.teamMembers
  const teamMembersIsLoading = useSelector(state => state.branches)?.loading
  const priviledge = storage.getActiveCompany().memberships[0].privilege;
  const branches = useSelector(state => state.branches)?.branches?.data || []
  
  const reassignLoading = useSelector(state => state.branches)?.reassignLoading


  const [addTeamMember, setAddTeamMember] = useState(false);
  const [filterMember, setMemberFilter] = useState({
    label: 'Active members',
    value: 1,
  });


  const [teamMembers, setTeamMembers] = useState([]);
  const [employeeName, setEmployeeName] = useState('');

  const [useWriteUp, setUseWriteUp] = useState(false);

  const [currentUser, setCurrentUser] = useState(null);

  const [showTask, setShowTask] = useState(false);

  const handleCloseDrawer = () => {
    setShowTask(false);
  };

  const openWriteUp = () => {
    setUseWriteUp(true);
  };

  const closeWriteUp = () => {
    setUseWriteUp(false);
  };

  const handleOpen = () => {
    setAddTeamMember(true);
  };
  const handleClose = () => {
    setAddTeamMember(false);
  };

  const handleChangeUserStatus = (data) => {
    dispatch(updateUserStatusAsync(data)).then((res)=> {
      if([400, 500].includes(res?.payload?.status)){
        toast.error(res?.payload?.message);
      } else {
        toast.success(`User was successfuly ${data?.status === 0 ? "deactivated": "activated"}`);
      }
    });
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSecond, setAnchorElSecond] = useState(null)
  const open = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const callback = () => {
    dispatch(getTeammemberAsync({branchId: params?.branchId,  statusId: filterMember.value})).then((res)=> {
      setTeamMembers(res?.payload?.data);
    })
  };

  useEffect(() => {
    callback();
    dispatch(getBranchesAsync())
  }, []);

  const myCallback = useCallback(() => {
    dispatch(getTeammemberAsync({branchId: params?.branchId, statusId: filterMember?.value})).then((res)=> {
      setTeamMembers(res?.payload?.data);
    })
  },[filterMember.value]);

  useEffect(() => {
    myCallback();
  }, [filterMember.value]);

  const handleOnCLickMember = (data) => {
    navigate(`/branches/${params?.branchId}/${data?.id}`, {
      state: {
        data,
        ...location?.state
      }
    })
    // setIndex(3);
    // setUser(data);
  };

  const handleBranchTab = () => {
    // setIndex(0);
    navigate(-1)
  };

  const handleEmployeeSearch = (EmployeeName) => {
    const filteredData = teamMembersStore?.filter((teamMember) => teamMember?.user.firstName?.toLowerCase().includes(EmployeeName?.toLowerCase()) || teamMember?.user.lastName?.toLowerCase().includes(EmployeeName?.toLowerCase()))
    return filteredData;
  }

  const handleOnChangeEmployeeName = (e) => {
    setEmployeeName(e.target.value);
  }

  const [openPopper, setOpenPopper] = useState(null);
  const [userInfo, setUserInfo] = useState(null)
  const [popperHovered, setPopperHovered] = useState(false);

  const [openLocationPopper, setOpenLocationPopper] = useState(null);
  
  
  
  const handlePopperMouseEnter = () => {
    setPopperHovered(true);
  };

  const handlePopperMouseLeave = () => {
    setPopperHovered(false);
  };


 
  const handleOpenPopper = (event, data) => {
    const user = {
      name: `${data.user?.firstName} ${data.user?.lastName}`,
      email: data.user?.email,
      jobRole: data?.jobRole,
      picture: data?.user?.picture,
      branch: data?.assignedBranch
    }
    setOpenPopper(event.target);
    setUserInfo(user)

  };

  const handleReassignLocation = (x) => {
    setOpenLocationPopper(null)
    dispatch(reassignBranchAsync({
      membershipId: currentUser?.id,
      locationId: x?.id
    }))
    .then(val => {
      if(val?.payload?.status === 200){
        toast.success('User Location changed')
        handleCloseMenu()
        callback()
      }else toast.error(val?.payload?.data?.message)
    })
  }

  const mainAnim = {
    exit: {
      opacity: 0,
      transition: { ease: 'easeOut', duration: 1 },
    },
  };

  const handleDirectSendBulkAccountCreation = () => {
    navigate("/branches/bulk-account", {
      state: {
        branchId: params?.branchId
      }
    })
  }



  return (
    <div className="team-members">
    <AppLayout
     custom="company"
     subCustom="branch"
     title="Team members"
     customTitle=" "
     subtitle="Details on team memebers"
     RightComponent={<div className='flex items-center gap-4'>
        <button onClick={handleOpen} data-tour="step3" className="p-3 rounded bg-5EB text-black flex items-center "> <BsPlus size={23} /> <span className="w-max">Add New Member</span></button>
         <button onClick={handleDirectSendBulkAccountCreation} className="p-3 rounded text-black border-2 flex items-center"> <CiLocationOn size={23} /> <span className="w-max">Add Bulk Members</span></button>
     </div>}
    >
      <AlertDialog />
      <AnimatePresence>
          {(openPopper || popperHovered) && (
            <motion.div variants={mainAnim} exit={'exit'}>
                <PopDetail
                  openPopper={openPopper}
                  setOpenPopper={setOpenPopper}
                  handlePopperMouseEnter={handlePopperMouseEnter}
                  handlePopperMouseLeave={handlePopperMouseLeave}
                  popperHovered={popperHovered}
                >
                  <UserDetail userInfo={userInfo}/>

                </PopDetail>
            </motion.div>
          )}
      </AnimatePresence>

      <div
        className={priviledge === 3 ? 'branch-name-no-margin' : 'branch-name flex items-center gap-3'}
      >
         <IoIosArrowBack onClick={handleBranchTab} size={20} className='cursor-pointer dark:text-white' />
        {priviledge !== 3 && (
          <p onClick={handleBranchTab} className="child-branch cursor-pointer text-[16px] font-semibold">
            {location?.state?.branchName.toUpperCase()} /
          </p>
        )}
        <p className='text-[16px] dark:text-white'>TEAM MEMBERS</p>
      </div>

      <div className="header w-full" style={{ marginTop: '0px' }}>
              <div className="flex items-center w-full">
                <div className="search-box !justify-start items-center mr-3 !border-6E6 dark:bg-828 dark:!border-333 !w-full !rounded-3xl">
                  <div>
                    <BsSearch className="dark:text-white" />
                  </div>
                  <input
                    onChange={handleOnChangeEmployeeName}
                    type="text"
                    placeholder="Search for team member"
                    className='dark:text-white w-full'
                  />
                </div>
                <CustomDropdown
                  dropdownTitleClass="text-14 text-black dark:text-white"
                  onChangeOption={(val) => {
                    setMemberFilter(val);
                  }}
                  optionClass="z-10 dark:bg-444"
                  className="mt-[1vh] w-[180px] py-3 h-[46px] dark:bg-828"
                  value={filterMember}
                  data={[
                    { value: 0, label: 'Inactive members' },
                    { value: 1, label: 'Active members' },
                  ]}
                />
              </div>

              {/* {teamMembersStore.length >= 0 && !teamMembersIsLoading && (
                <InviteButton handleOpen={handleOpen} />
              )} */}
            </div>

      <section className="inner-section inner-section-team dark:bg-primary">
          <>
            

            {!teamMembersIsLoading && (<div className="main-body">
              <section className="table mb-10">
                {handleEmployeeSearch(employeeName).length > 0 && (
                  <table className='font-bold text-left'>
                    <thead className='dark:bg-444 bg-BF2'>
                      <td className='dark:bg-444 bg-BF2 dark:!text-white border border-6E6 dark:border-333'>Employee name</td>
                      <td className='dark:bg-444 bg-BF2 dark:!text-white border border-6E6 dark:border-333'>Email</td>
                      <td className='dark:bg-444 bg-BF2 dark:!text-white border border-6E6 dark:border-333'>Date Joined</td>
                      <td className='dark:bg-444 bg-BF2 dark:!text-white border border-6E6 dark:border-333'>Job Role</td>
                      <td className='dark:bg-444 bg-BF2 dark:!text-white border border-6E6 dark:border-333'>Status</td>
                      <td className='dark:bg-444 bg-BF2 dark:!text-white'> </td>
                    </thead>
                    {handleEmployeeSearch(employeeName)?.map((val) => (
                      <tr
                        className="cursor-pointer bg-AFA"
                        onClick={() => handleOnCLickMember(val)}
                        // onMouseEnter={(event)=> {
                        //   let timeoutId = setTimeout(() => {
                        //     handleOpenPopper(event, val);
                        //   }, 500); 
                        //   event.currentTarget.addEventListener('mouseleave', () => {
                        //     clearTimeout(timeoutId);
                        //   });
                        // }}
                        // onMouseLeave={()=>setOpenPopper(null)}
                        
                      >
                        <td className='dark:!border-333 border dark:bg-828 border-6E6'>
                          <div className="flex items-center">
                            {val.user?.picture?.url ? (
                              <img
                                className="rounded-full h-10 w-10 mr-2"
                                src={val.user?.picture?.url}
                                alt="logo"
                              />
                            ) : (
                              <InitialsGen
                                width={40}
                                name={`${
                                  val.user.firstName + ' ' + val.user.lastName
                                }`}
                                bgColor="blue"
                              />
                            )}
                            <p className='dark:text-white'>
                              {val.user.firstName + ' ' + val.user.lastName}
                            </p>
                          </div>
                        </td>
                        <td className="dark:!border-333 border border-6E6 dark:bg-828"><p className='dark:text-white'>{val.user.email}</p></td>
                        {/* <td><span className='role'>{val.privilege === 2 ? 'Admin':(val.privilege === 3 ? 'Member': 'Super admin')    }</span></td> */}

                        <td className='dark:!border-333 border border-6E6 dark:bg-828'><p className='dark:text-white'>{moment(val.createdOn).format('do MMM, yyyy')}</p></td>
                        <td className='dark:!border-333 border border-6E6 dark:bg-828'>
                        <p className='dark:text-white'>{val.jobRole === null ? '-' : val.jobRole.title}</p>
                        </td>
                        <td className='dark:!border-333 border border-6E6 dark:bg-828'><p className='dark:text-white'>{val.status === 1 ? 'active' : 'inactive'}</p></td>
                        <td
                          style={{
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            fontSize: '20px',
                          }}
                          className="dark:text-white dark:!border-b-333 dark:bg-828"
                        >
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              setCurrentUser(val);
                              handleClickMenu(e);
                            }}
                          >
                            ...
                          </span>
                        </td>
                      </tr>
                    ))}
                  </table>
                )}
                {handleEmployeeSearch(employeeName).length < 1 && (
                  <PlaceHolderOne />
                )}
              </section>
            </div>)}
          </>
        
        {teamMembersIsLoading && (
          <Stack direction="row" justifyContent="center" mt={10}>
            <CircularProgress />
          </Stack>
        )}

        
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={!reassignLoading && handleCloseMenu}
          style={{ left: '-6vw'}}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {!reassignLoading ?
            <div className='dark:bg-444 dark:border-333 dark:border-1 dark:text-white py-3'>

              <CustomItem  handleClick={openWriteUp}>
                  <img
                    src={WriteUpIcon}
                    width="20px"
                    style={{ marginRight: '7px' }}
                    alt="pen"
                  />
                <p style={{ fontSize: '14px' }}>Write up</p>{' '}
              </CustomItem>

              <CustomItem
                className="menu-text"
                handleClick={() => {
                  setShowTask(true);
                  handleCloseMenu();
                }}
              >
                <p>
                  <img
                    src={AssignTaskIcon}
                    width="20px"
                    alt="task"
                  />
                </p>
                <p className='text-[14px]'>Assign Task</p>{' '}
              </CustomItem>

              <CustomItem
                className="menu-text"
                handleClick={() => {
                  handleChangeUserStatus({
                    status: currentUser?.status === 0 ? 1 : 0,
                    membershipId: currentUser?.id,
                  });
                  handleCloseMenu();
                }}
              >
                <p>
                  <img src={DisableIcon} alt="img" width="20px" />
                </p>
                <p style={{ fontSize: '14px' }}>
                  {currentUser?.status === 1 ? 'Disable' : 'Enable'}
                </p>{' '}
                  
              </CustomItem>

              <CustomItem
                className=""
                handleClick={(e)=> {
                  setOpenLocationPopper(openLocationPopper ? null : e.currentTarget)
                }}
              >
                <div className="flex items-center gap-2">
                <p>
                  <img src={ReassignIcon} alt="img" width="20px" />
                </p>
                <p className='text-[14px]'> Reassign Location</p>
                </div>

                  {openLocationPopper&&<CustomPopper 
                    openPopper={openLocationPopper} 
                    handleClose={()=> setOpenLocationPopper(null)} 
                    placement='left-start'
                    className='z-[1500] mr-5 dark:bg-138 dark:text-white'
                    >
                    <div className='max-h-[200px] overflow-y-auto mt-1 py-3 font-bold flex flex-col gap-1 text-[14px] bg-white rounded-lg shadow-shift-shadow-small dark:bg-138'>
                      {branches?.map(x => (

                        <CustomItem 
                          handleClick={()=>handleReassignLocation(x)}
                          className='gap-3 !justify-start text-[14px]'>
                          <p className='w-2 h-2 rounded-full' style={{backgroundColor: x?.hexcode}}/>
                          <p>{x?.name}</p>
                          
                        </CustomItem>

                      ))}
                    </div>
                  </CustomPopper>}

                 
              </CustomItem>
            </div>
          :
            <div className='h-[100px] w-[100px] flex flex-col justify-center items-center dark:bg-138'>
              <CircularProgress/>
            </div>
          }

        </Menu>
           
      </section>

      <TeamMemberModal
        open={addTeamMember}
        handleClose={handleClose}
        callback={callback}
        branchId={params?.branchId}
      />

      <WriteUpModal
        open={useWriteUp}
        handleClose={closeWriteUp}
        userId={currentUser !== null ? currentUser.user.id : ''}
        branchId={currentUser !== null ? currentUser.assignedBranchId : ''}
      />

      

      <AnimatePresence>
        {showTask && (
          <CreateTask
            branchId={params?.branchId}
            currentUser={currentUser.user}
            openDrawer={showTask}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
      </AnimatePresence>

    </AppLayout>
    </div>
  );
};


const CustomItem = ({ children, handleClick, className }) => {
  return (
      <div onClick={handleClick}
          className={`hover:bg-A5A hover:text-white cursor-pointer flex gap-2 items-center py-2 px-4 ${className}`}>
              {children}
      </div>
  )
}